import axios from "axios"

export async function getOrders(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/conferences/booking/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getOrderDetail(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/conferences/booking-by-order-id/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data.booking
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}


export async function exportOrders(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/csv/download-booking`, {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            data: {conference_id: id}
        })
        if (res.data) {

            const file = new Blob(
                [res.data], 
                {type: 'text/csv'});

            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);

            return res
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function sendOrderRecapMail(id) {
    try {
        const token = localStorage.getItem('token')
        const res = await axios(`${process.env.REACT_APP_API_HOST}/api/conferences/send-recap-by-order-id/${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })
        if (res.data.status === "success") {
            return res.data
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}
