import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import FormHelperText from '@mui/material/FormHelperText';
import { useFormik } from "formik";
import * as Yup from "yup";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { getUserDetail, setUserDetail } from "../../../utils/usersApi";
import Feedback from "../../../components/Feedback";
import { getCountries } from "../../../utils/api";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tab,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { handleFileUpload } from "../../../utils";
import { format } from "date-fns";

const validationSchema = Yup.object().shape({
  billing_information : Yup.object().shape({
    territory: Yup.string().required("Campo obbligatorio"),
    invoice_type: Yup.string().required("Campo obbligatorio"),
    address: Yup.string().required("Campo obbligatorio"),
    postal_code: Yup.string().required("Campo obbligatorio"),
    city: Yup.string().required("Campo obbligatorio"),
    country: Yup.string().required("Campo obbligatorio"),
    //conditional fields
    // VIES
    vies: Yup.string()
    .nullable()
    .when("territory", {
      is: "UE",
      then: Yup.string().required("Campo obbligatorio"),
    }),
    // VAT
    vat: Yup.string()
    .nullable()
    .when("vies", {
      is: (vies) => vies === "true",
      then: Yup.string().required("VAT obbligatorio"),
    })
    .when("territory", {
      is: "Italy",
      then: Yup.string().required("VAT o Codice fiscale obbligatorio"),
    }),
    // FISCAL CODE
    fiscal_code: Yup.string()
    .nullable()
    .when("vies", {
      is: (vies) => vies === "false",
      then: Yup.string().nullable().required("Codice fiscale obbligatorio"),
    })
    .when("territory", {
      is: "Italy",
      then: Yup.string().required("Codice fiscale o VAT obbligatorio"),
    }),
    // RECIPIENT CODE
    recipient_code: Yup.string()
    .nullable()
    .when("territory", {
      is: "Italy",
      then: Yup.string().required("Campo obbligatorio"),
    }),
  })
});

const Input = styled("input")({
  display: "none",
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const defaultState = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  fiscal_code: "",
  field_of_activity: "",
  active: false,
  speaker: false,
  image: "",
  email_alternative: "",
  status: "",
  member_area: false,
  remove_fee: false,
  membership: false,
  profile_id: "",
  profile_name: "",
  company: {},
  billing_information: {
    territory: "",
    invoice_type: "",
    company_name: "",
    name: "",
    surname: "",
    vat: "",
    country: "",
    address: "",
    postal_code: "",
    //province: "",
    city: "",
    recipient_code: "",
    vies: false,
    fiscal_code: ""
  },
  subscription_date_end: new Date(),
  subscription_trial_end: new Date(),
};

const profiles = [
  { id: 1, name: "client" },
  { id: 2, name: "expert" },
  { id: 3, name: "admin" },
  { id: 4, name: "arbitrator" },
];
const statuses = [
  { name: "trialing" },
  { name: "active" },
  { name: "canceled" },
  { name: "incomplete_expired" },
];

const fieldOfActivities = [
  {
    name: "Company",
    value: "company"  
  },
  {
    name: "Law firm",
    value: "Law firm"  
  },
  {
    name: "Other",
    value: "other"  
  },
]
const territories = [
  {
    name: "Italy",
    value: "Italy",
  },
  {
    name: "UE",
    value: "UE",
  },
  {
    name: "Extra UE",
    value: "Extra UE",
  },
];

const viesValues = [
  {
    name: "Sì",
    value: 1,
  },
  {
    name: "No",
    value: 0,
  },
];

const invoiceTypes = [
  {
    name: "Individual",
    value: "individual"
  },
  {
    name: "Company",
    value: "company"
  },
  {
    name: "Law firm",
    value: "law firm"
  },
  {
    name: "Lawyer",
    value: "lawyer"
  },
  {
    name: "Association",
    value: "association"
  },
];

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
});


export default function UserDetail() {
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const { id } = useParams();
  const [tabValue, setTabValue] = React.useState("user");
  const [userResetLinkData, setUserResetLinkData] = useState({});
  const [resetPasswordLink, setResetPasswordLink] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: defaultState,
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      save(values);
    },
  });

  const save = async (values) => {
    const userDetail = { ...values };
    if (values.subscription_date_end)
      userDetail.subscription_date_end = format(
        new Date(values.subscription_date_end),
        "yyyy-MM-dd"
      );

    const resUser = await setUserDetail(userDetail);
    if (resUser) {
      setFeedbackMessage("Utente Salvato");
      setShowSnackBar(true);
    } else {
      setFeedbackMessage("Qualcosa è andato storto");
      setShowSnackBar(true);
    }
  };

  const fileChange = async (e, field) => {
    handleFileUpload(e, (res) => formik.setFieldValue(field, res));
  };

  const getFormikValues = (type, field) =>
    formik.values[type] && formik.values[type][field];

  const getFormikErrors = (type, field) =>
    formik.errors[type] && formik.errors[type][field];

  useEffect(() => {
    let subscribed = true;
    async function fetchData() {
      if (subscribed) {
        const resCountries = await getCountries();
        if (resCountries) {
          setCountries(resCountries);
        }

        if (id) {
          const res = await getUserDetail(id);
          setUserResetLinkData({
            ...userResetLinkData,
            last_reset_password: res.last_reset_password,
            last_reset_password_link_sent: res.last_reset_password_link_sent,
          });
          if (res) {
            if (res.billing_information === null) {
              const emptyRes = JSON.parse(JSON.stringify(res));
              emptyRes.billing_information = {
                territory: "",
                invoice_type: "",
                company_name: "",
                name: "",
                surname: "",
                vat: "",
                country: "",
                address: "",
                postal_code: "",
                province: "",
                city: "",
                recipient_code: "",
                vies: false,
                fiscal_code: ""
              }
              formik.setValues(emptyRes);
            } else {
              const formikRes = JSON.parse(JSON.stringify(res));
              formikRes.billing_information = {
                territory: res.billing_information.territory,
                invoice_type: res.billing_information.invoice_type,
                company_name: res.billing_information.company_name,
                name: res.billing_information.name,
                surname: res.billing_information.surname,
                vat: res.billing_information.vat,
                country: res.billing_information.country,
                address: res.billing_information.address,
                postal_code: res.billing_information.postal_code,
                province: res.billing_information.province,
                city: res.billing_information.city,
                recipient_code: res.billing_information.recipient_code,
                vies: res.billing_information.vies === null ? "" : res.billing_information.vies,
                fiscal_code: res.billing_information.fiscal_code === null ? "" : res.billing_information.fiscal_code
              }
              formik.setValues(formikRes);
            }
          }
        }
      }
    }

    fetchData(id);

    return () => {
      subscribed = false;
    }
  }, []);


  // reset password

  //get password reset link

  async function getResetPasswordLink() {
    const token = localStorage.getItem("token");

    const res = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/users/generate-password-link/${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const result = await res.json();
    setResetPasswordLink(result.password_link);
  }

  useEffect(() => {
    console.log(formik.errors && formik.errors.billing_information?.city)
  }, [formik.errors]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ width: "100%" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Dettagli Utente" value="user" />
                <Tab label="Membership" value="membership" />
                <Tab label="Dettagli Azienda" value="company" />
                <Tab label="Informazioni di fatturazione" value="billing" />
              </TabList>
            </Box>
            {/* MEMBERSHIP */}
            <TabPanel value="membership">
              <p>N.B.: Per poter modificare i dati relativi alla membership è obbligatorio controllare che le informazioni di fatturazione obbligatorie siano compilate.</p>
              <Stack spacing={4}>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Item>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.active ? true : false}
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "active",
                                  !formik.values.active
                                );
                              }}
                              inputProps={{
                                name: "active",
                                "aria-label": "controlled",
                              }}
                            />
                          }
                          label="Stato di attivazione"
                        />
                        
                      </Item>
                      <Item>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.speaker ? true : false}
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "speaker",
                                  !formik.values.speaker
                                );
                              }}
                              inputProps={{
                                name: "speaker",
                                "aria-label": "controlled",
                              }}
                            />
                          }
                          label="Speaker"
                        />
                      </Item>
                      <Item>
                        <div>
                          <p>
                            Membership:{" "}
                            <Chip
                              label={
                                formik.values.membership
                                  ? "Attiva"
                                  : "Non Attiva"
                              }
                            />
                          </p>
                        </div>
                        <div>
                          <p>
                            Tipo Membro:{" "}
                            <Chip
                              label={
                                formik.values.company_founder
                                  ? "Member"
                                  : "Additional Member"
                              }
                            />
                          </p>
                        </div>
                        <div>
                          <p>
                            Scadenza:{" "}
                            <DesktopDatePicker
                              label="Scadenza"
                              inputFormat="dd/MM/yyyy"
                              disabled={
                                formik.values.status === "trialing"
                                  ? true
                                  : false
                              }
                              value={
                                new Date(
                                  formik.values.status === "trialing"
                                    ? formik.values.subscription_trial_end
                                    : formik.values.subscription_date_end
                                )
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  formik.values.status !== "trialing"
                                    ? "subscription_date_end"
                                    : "subscription_trial_end",
                                  e
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </p>
                        </div>

                        <div>
                          {formik.values.membership && (
                            <Button
                              variant="contained"
                              type="button"
                              onClick={() =>
                                navigate(
                                  `/memberships/${formik.values.subscription_id}`
                                )
                              }
                            >
                              Vai al Dettaglio Membership
                            </Button>
                          )}
                        </div>
                      </Item>
                      <Item>
                        <FormControl fullWidth>
                          <InputLabel id="status-label">Status</InputLabel>
                          <Select
                            labelId="status-label"
                            id="status"
                            name="status"
                            value={formik.values.status}
                            label="Status"
                            onChange={formik.handleChange}
                            error={formik.errors.status}
                            helperText={formik.errors.status}
                          >
                            {statuses.map((status) => (
                              <MenuItem key={status.name} value={status.name}>
                                {status.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Item>
                      <Item>
                        <FormControl fullWidth>
                          <InputLabel id="profile_id-label">Profilo</InputLabel>
                          <Select
                            labelId="profile_id-label"
                            id="profile_id"
                            name="profile_id"
                            value={formik.values.profile_id}
                            label="Profilo"
                            onChange={formik.handleChange}
                            error={formik.errors.profile_id}
                            helperText={formik.errors.profile_id}
                          >
                            {profiles.map((profile) => (
                              <MenuItem key={profile.id} value={profile.id}>
                                {profile.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {formik.values.profile_name === "expert" && (
                          <Button
                            variant="contained"
                            type="button"
                            onClick={() =>
                              navigate(`/experts/${formik.values.expert_id}`)
                            }
                          >
                            Vai al Dettaglio Esperto
                          </Button>
                        )}
                      </Item>
                    </Grid>
                  </Grid>
                </div>
              </Stack>
            </TabPanel>
            {/* USER */}
            <TabPanel value="user">
              <Stack spacing={4}>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Item>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.member_area ? true : false}
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "member_area",
                                  !formik.values.member_area
                                );
                              }}
                              inputProps={{
                                name: "member_area",
                                "aria-label": "controlled",
                              }}
                            />
                          }
                          label="Member Area"
                        />
                      </Item>
                      <Item>
                        <Button
                          variant="contained"
                          component="span"
                          onClick={getResetPasswordLink}
                        >
                          Reset password link
                        </Button>
                      </Item>
                      {resetPasswordLink != null ? (
                        <Item>
                          <strong>Reset link: </strong>
                          {resetPasswordLink}
                        </Item>
                      ) : (
                        ""
                      )}
                      <Item>
                        <strong>Ultimo reset password:</strong>{" "}
                        {userResetLinkData.last_reset_password != null
                          ? userResetLinkData.last_reset_password
                          : "Mai effettuato"}{" "}
                        <br></br>
                        <strong>
                          Ultimo invio di link per il reset password:
                        </strong>{" "}
                        {userResetLinkData.last_reset_password_link_sent != null
                          ? userResetLinkData.last_reset_password_link_sent
                          : "Mai effettuato"}
                      </Item>
                      <Item>
                        <img
                          src={
                            formik.values.image &&
                            `${process.env.REACT_APP_API_HOST}/api/image/${formik.values.image}`
                          }
                          alt=""
                          width="100"
                        />
                        <label htmlFor={`contained-button-image`}>
                          <Input
                            accept="image/*"
                            id={`contained-button-image`}
                            type="file"
                            onChange={(event) => fileChange(event, "image")}
                          />
                          <Button variant="contained" component="span">
                            Upload Immagine
                          </Button>
                        </label>
                      </Item>
                      <Item>
                        {/* name */}
                        <TextField
                          name="name"
                          fullWidth
                          id="name"
                          label={"Nome"}
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={formik.errors.name}
                          helperText={formik.errors.name}
                        />
                      </Item>
                      {/* surname */}
                      <Item>
                        <TextField
                          name="surname"
                          fullWidth
                          id="surname"
                          label={"Cognome"}
                          value={formik.values.surname}
                          onChange={formik.handleChange}
                          error={formik.errors.surname}
                          helperText={formik.errors.surname}
                        />
                      </Item>
                      {/* email */}
                      <Item>
                        <TextField
                          name="email"
                          fullWidth
                          id="email"
                          label={"Email"}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.errors.email}
                          helperText={formik.errors.email}
                          // disabled
                        />
                      </Item>
                      {/* phone */}
                      <Item>
                        <TextField
                          name="phone"
                          fullWidth
                          id="phone"
                          label={"Telefono"}
                          value={formik.values.phone || ""}
                          onChange={formik.handleChange}
                          error={formik.errors.phone}
                          helperText={formik.errors.phone}
                        />
                      </Item>
                      {/* fiscal code */}
                      <Item>
                        <TextField
                          name="fiscal_code"
                          fullWidth
                          id="fiscal_code"
                          label={"Codice Fiscale"}
                          value={formik.values.fiscal_code || ""}
                          onChange={formik.handleChange}
                          error={formik.errors.fiscal_code}
                          helperText={formik.errors.fiscal_code}
                        />
                      </Item>
                      {/* field of activity */}
                      <Item>
                        <FormControl fullWidth>
                          <InputLabel id="field-of-activity">Working at</InputLabel>
                          <Select
                            labelId="field-of-activity"
                            id="field_of_activity"
                            name="field_of_activity"
                            value={formik.values.field_of_activity}
                            label="Field of activity"
                            onChange={formik.handleChange}
                          >
                            {fieldOfActivities.map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Item>
                      <Item>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.remove_fee ? true : false}
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "remove_fee",
                                  !formik.values.remove_fee
                                );
                              }}
                              inputProps={{
                                name: "remove_fee",
                                "aria-label": "controlled",
                              }}
                            />
                          }
                          label="Rimuovi Fee Conferenza"
                        />
                      </Item>
                    </Grid>
                  </Grid>
                </div>
              </Stack>
            </TabPanel>
            {/* COMPANY DATA */}
            <TabPanel value="company">
              <Stack spacing={4}>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Item>
                        <CssTextField
                          name="company.name"
                          fullWidth
                          id="company_name"
                          label={"Nome"}
                          value={getFormikValues("company", "name")}
                          onChange={formik.handleChange}
                          error={getFormikErrors("company", "name")}
                          helperText={getFormikErrors("company", "name")}
                          disabled
                        />
                      </Item>
                      <Item>
                        <CssTextField
                          name="company.type"
                          fullWidth
                          id="company_type"
                          label={"Tipo"}
                          value={getFormikValues("company", "type")}
                          onChange={formik.handleChange}
                          error={getFormikErrors("company", "type")}
                          helperText={getFormikErrors("company", "type")}
                          disabled
                        />
                      </Item>
                      <Item>
                        <CssTextField
                          name="company.address"
                          fullWidth
                          id="company_address"
                          label={"Indirizzo"}
                          value={getFormikValues("company", "address")}
                          onChange={formik.handleChange}
                          error={getFormikErrors("company", "address")}
                          helperText={getFormikErrors("company", "address")}
                          disabled
                        />
                      </Item>
                      <Item>
                        <CssTextField
                          name="company.city"
                          fullWidth
                          id="company_city"
                          label={"Città"}
                          value={getFormikValues("company", "city")}
                          onChange={formik.handleChange}
                          error={getFormikErrors("company", "city")}
                          helperText={getFormikErrors("company", "city")}
                          disabled
                        />
                      </Item>
                      {/* Province field */}
                      <Item>
                        <CssTextField
                          name="company.province"
                          fullWidth
                          id="company_province"
                          label={"Provincia"}
                          value={getFormikValues("company", "province")}
                          onChange={formik.handleChange}
                          error={getFormikErrors("company", "province")}
                          helperText={getFormikErrors("company", "province")}
                          disabled
                        />
                      </Item>
                      <Item>
                        <CssTextField
                          name="company.postal_code"
                          fullWidth
                          id="company_postal_code"
                          label={"CAP"}
                          value={getFormikValues("company", "postal_code")}
                          onChange={formik.handleChange}
                          error={getFormikErrors("company", "postal_code")}
                          helperText={getFormikErrors("company", "postal_code")}
                          disabled
                        />
                      </Item>
                      <Item>
                        <CssTextField
                          name="company.country"
                          fullWidth
                          id="company_country"
                          label={"CAP"}
                          value={getFormikValues("company", "country")}
                          onChange={formik.handleChange}
                          error={getFormikErrors("company", "country")}
                          helperText={getFormikErrors("company", "country")}
                          disabled
                        />
                      </Item>
                      <Item>
                        <CssTextField
                          name="company.vat"
                          fullWidth
                          id="company_vat"
                          label={"VAT"}
                          value={formik.values.company.vat}
                          onChange={formik.handleChange}
                          error={
                            formik.errors.company && formik.errors.company.vat
                          }
                          helperText={
                            formik.errors.company && formik.errors.company.vat
                          }
                          disabled
                        />
                      </Item>
                    </Grid>
                  </Grid>
                </div>
              </Stack>
            </TabPanel>
            {/* BILLING INFORMATION */}
            <TabPanel value="billing">
              <Stack spacing={4}>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* territory */}
                      <Item>
                      <FormControl fullWidth>
                        <InputLabel id="territory-label">Territorio</InputLabel>
                        <Select
                          labelId="territory-label"
                          name="billing_information.territory"
                          id="billing_information_territory"
                          label="Territorio"
                          value={getFormikValues(
                            "billing_information",
                            "territory"
                          )}
                          error={formik.errors.billing_information?.hasOwnProperty("territory")}
                          onChange={formik.handleChange}
                        >
                          <MenuItem disabled value="">
                            <em>Territorio</em>
                          </MenuItem>
                          {territories.map((territory) => (
                            <MenuItem
                              key={territory.name}
                              value={territory.value}
                            >
                              {territory.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.errors.billing_information?.territory && <FormHelperText>{formik.errors.billing_information?.territory}</FormHelperText>}
                      </FormControl>
                      </Item>
                      {/* Name */}
                      <Item>
                        <TextField
                          name="billing_information.name"
                          fullWidth
                          id="billing_information_name"
                          label={"Nome"}
                          value={getFormikValues("billing_information", "name")}
                          onChange={formik.handleChange}
                          // error={getFormikErrors("billing_information", "name")}
                          // helperText={getFormikErrors(
                          //   "billing_information",
                          //   "name"
                          // )}
                        />
                      </Item>
                      {/* Surname */}
                      <Item>
                        <TextField
                          name="billing_information.surname"
                          fullWidth
                          id="billing_information_surname"
                          label={"Cognome"}
                          value={getFormikValues(
                            "billing_information",
                            "surname"
                          )}
                          onChange={formik.handleChange}
                          // error={getFormikErrors(
                          //   "billing_information",
                          //   "surname"
                          // )}
                          // helperText={getFormikErrors(
                          //   "billing_information",
                          //   "surname"
                          // )}
                        />
                      </Item>
                      {/* Company name */}
                      <Item>
                        <TextField
                          name="billing_information.company_name"
                          fullWidth
                          id="billing_information_company_name"
                          label={"Ragione Sociale"}
                          value={getFormikValues(
                            "billing_information",
                            "company_name"
                          )}
                          onChange={formik.handleChange}
                          // error={getFormikErrors(
                          //   "billing_information",
                          //   "company_name"
                          // )}
                          // helperText={getFormikErrors(
                          //   "billing_information",
                          //   "company_name"
                          // )}
                        />
                      </Item>
                      {/* Address */}
                      <Item>
                        <TextField
                          name="billing_information.address"
                          fullWidth
                          id="billing_information_address"
                          label={"Indirizzo"}
                          value={getFormikValues(
                            "billing_information",
                            "address"
                          )}
                          onChange={formik.handleChange}
                          error={formik.errors.billing_information?.hasOwnProperty("address")}
                          helperText={formik.errors.billing_information?.address}
                        />
                      </Item>
                      {/* City */}
                      <Item>
                        <TextField
                          name="billing_information.city"
                          fullWidth
                          id="billing_information_city"
                          label={"Città"}
                          value={getFormikValues("billing_information", "city")}
                          onChange={formik.handleChange}
                          error={formik.errors.billing_information?.hasOwnProperty("city")}
                          helperText={formik.errors.billing_information?.city}
                        />
                      </Item>
                      {/* Province */}
                      {/* <Item>
                        <TextField
                          name="billing_information.province"
                          fullWidth
                          id="billing_information_province"
                          label={"Provincia"}
                          value={getFormikValues(
                            "billing_information",
                            "province"
                          )}
                          onChange={formik.handleChange}
                          // error={getFormikErrors(
                          //   "billing_information",
                          //   "province"
                          // )}
                          // helperText={getFormikErrors(
                          //   "billing_information",
                          //   "province"
                          // )}
                        />
                      </Item> */}
                      {/* Postal code */}
                      <Item>
                        <TextField
                          name="billing_information.postal_code"
                          fullWidth
                          id="billing_information_postal_code"
                          label={"CAP"}
                          value={getFormikValues(
                            "billing_information",
                            "postal_code"
                          )}
                          onChange={formik.handleChange}
                          error={formik.errors.billing_information?.hasOwnProperty("postal_code")}
                          helperText={formik.errors.billing_information?.postal_code }
                        />
                      </Item>
                      {/* Country */}
                      <Item>
                        <FormControl fullWidth>
                          <InputLabel id="billing_information_country-label">
                            Paese
                          </InputLabel>
                          <Select
                            labelId="billing_information_country-label"
                            id="billing_information_country"
                            name="billing_information.country"
                            value={getFormikValues(
                              "billing_information",
                              "country"
                            )}
                            label="Paese"
                            onChange={formik.handleChange}
                            error={formik.errors.billing_information?.hasOwnProperty("country")}
                          >
                            <MenuItem disabled value="">
                              <em>VIES</em>
                            </MenuItem>
                            {countries.map((country) => (
                              <MenuItem key={country.name} value={country.name}>
                                {country.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.errors.billing_information?.country && <FormHelperText>{formik.errors.billing_information?.country}</FormHelperText>}
                        </FormControl>
                      </Item>
                      {/* VIES */}
                      <Item>
                        <FormControl fullWidth>
                        <InputLabel id="vies-label">VIES</InputLabel>
                        <Select
                          labelId="vies-label"
                          name="billing_information.vies"
                          id="billing_information_vies"
                          label={"VIES"}
                          value={getFormikValues("billing_information", "vies")}
                          onChange={formik.handleChange}
                          error={formik.errors.billing_information?.hasOwnProperty("vies")}
                        >
                          {viesValues.map((vies) => (
                            <MenuItem key={vies.name} value={vies.value}>
                              {vies.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.errors.billing_information?.vies  && <FormHelperText>{formik.errors.billing_information?.vies}</FormHelperText>} 
                        </FormControl>
                      </Item>
                      {/* VAT */}
                      <Item>
                        <TextField
                          name="billing_information.vat"
                          fullWidth
                          id="billing_information_vat"
                          label={"VAT"}
                          value={getFormikValues("billing_information", "vat")}
                          onChange={formik.handleChange}
                          error={formik.errors.billing_information?.hasOwnProperty("vat")}
                          helperText={formik.errors.billing_information?.vat}
                        />
                      </Item>
                      {/* Fiscal code */}
                      <Item>
                        <TextField
                          name="billing_information.fiscal_code"
                          fullWidth
                          id="billing_information_fiscal_code"
                          label={"Codice Fiscale"}
                          value={getFormikValues("billing_information", "fiscal_code") }
                          onChange={formik.handleChange}
                          error={formik.errors.billing_information?.hasOwnProperty("fiscal_code")}
                          helperText={formik.errors.billing_information?.fiscal_code}
                        />
                      </Item>
                      {/* Recipient code */}
                      <Item>
                        <TextField
                          name="billing_information.recipient_code"
                          fullWidth
                          id="billing_information_recipient_code"
                          label={"Recipient Code"}
                          value={getFormikValues(
                            "billing_information",
                            "recipient_code"
                          )}
                          onChange={formik.handleChange}
                          error={formik.errors.billing_information?.hasOwnProperty("recipient_code")}
                          helperText={formik.errors.billing_information?.recipient_code }

                        />
                      </Item>
                      {/* Invoice Type */}
                      <Item>
                      <FormControl fullWidth>
                      <InputLabel id="invoice-type-label">Tipo Fattura</InputLabel>
                        <Select
                          labelId="invoice-type-label"
                          name="billing_information.invoice_type"
                          id="billing_information_invoice_type"
                          value={getFormikValues(
                            "billing_information",
                            "invoice_type")}
                          label={"Tipo Fattura"}
                          onChange={formik.handleChange}
                          error={formik.errors.billing_information?.hasOwnProperty("invoice_type")}
                        >
                          <MenuItem disabled value="">
                            <em>Tipo fattura</em>
                          </MenuItem>
                          {invoiceTypes.map((invoiceType) => (
                              <MenuItem key={invoiceType.name} value={invoiceType.value}>
                                {invoiceType.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {formik.errors.billing_information?.invoice_type  && <FormHelperText>{formik.errors.billing_information?.invoice_type}</FormHelperText>} 
                      </FormControl>
                      </Item>
                    </Grid>
                  </Grid>
                </div>
              </Stack>
            </TabPanel>
          </TabContext>
        </Box>

        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </LocalizationProvider>
      <Feedback
        open={showSnackBar}
        handleClose={() => setShowSnackBar(false)}
        message={feedbackMessage}
      />
    </>
  );
}
